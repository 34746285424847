import ng from 'angular';

// @ngInject
function acpModalStateCtrl(
  $scope,
  $state,
  $show,
  $element,
  $document,
  $hide,
  $cancel
) {
  var options = ($state.current.data && $state.current.data.modalOptions) || {};
  options = ng.extend(
    {
      // This is the element contents, but we detach it so $show does not try to
      // re-attach it later.
      element: $element.detach(),
      /* eslint-disable-next-line es5/no-es6-methods */
      parent: $document.find('body'),
      skipCompile: true,
      escapeToClose: false, // This needs to be false, true allows users to go to an empty screen with just the footer issuer statement
      scope: ng.extend(options.scope || $scope.$new(), {
        $hide: $hide,
        $cancel: $cancel
      })
    },
    options
  );

  $show(options);
}

export default acpModalStateCtrl;
